/**
 * 获取会员中心左侧菜单
 */
export function getUcenterCollapseItems() {
  return [
    {
      id: 1,
      title: "我的订单",
      url: "/order",
    },
    {
      id: 2,
      title: "我的金币",
      url: "/gold",
    },
    // {
    //   id: 3,
    //   title: "我的售后",
    //   url: "",
    // },
  ];
}
